import axios from 'axios';
import { User } from './AuthProvider';

export const usercolors = [
    '#30bced',
    '#6eeb83',
    '#ffbc42',
    '#ecd444',
    '#ee6352',
    '#9ac2c9',
    '#8acb88',
    '#1be7ff'
];

const keycloakConfig = {
    url: import.meta.env.VITE_AUTHORITY,
    realm: import.meta.env.VITE_REALM,
    clientId: import.meta.env.VITE_CLIENT_ID,
    redirectUri: window.location.origin + '/cb',
    logoutRedirectUri: window.location.origin + '/cb-logout'
};

const tokenEndpoint = `${keycloakConfig.url}/realms/${keycloakConfig.realm}/protocol/openid-connect/token`;

export type KeycloakTokenResponse = {
    access_token: string;
    expires_in: number;
    id_token: string;
    not_before_policy: number;
    refresh_expires_in: number;
    refresh_token: string;
    scope: string;
    session_state: string;
    token_type: string;
};

export const redirectToLogin = () => {
    const authUrl = `${keycloakConfig.url}/realms/${keycloakConfig.realm}/protocol/openid-connect/auth?client_id=${keycloakConfig.clientId}&redirect_uri=${encodeURIComponent(keycloakConfig.redirectUri)}&response_type=code&scope=openid&prompt=login`;
    window.location.href = authUrl;
};

export const saveTokenResponseToStorage = (tokenResponse: KeycloakTokenResponse) => {
    localStorage.setItem('auth', JSON.stringify(tokenResponse));
};

export const getTokenResponseFromStorage = (): KeycloakTokenResponse | null => {
    const auth = localStorage.getItem('auth');
    if (!auth) {
        return null;
    }

    return JSON.parse(auth) as KeycloakTokenResponse;
};

export const handleCallback = async (code: string) => {
    const response = await axios.post(
        tokenEndpoint,
        new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: keycloakConfig.clientId,
            redirect_uri: keycloakConfig.redirectUri,
            code: code
        }),
        {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }
    );

    saveTokenResponseToStorage(response.data);

    return response.data as KeycloakTokenResponse;
};

// src/auth/authService.js
export const refreshToken = async () => {
    const refreshToken = getTokenResponseFromStorage()?.refresh_token;

    if (!refreshToken) {
        throw new Error('No refresh token found');
    }

    const response = await axios.post(
        tokenEndpoint,
        new URLSearchParams({
            grant_type: 'refresh_token',
            client_id: keycloakConfig.clientId,
            refresh_token: refreshToken
        }),
        {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }
    );

    saveTokenResponseToStorage(response.data);

    return response.data as KeycloakTokenResponse;
};

export const redirectToLogout = () => {
    const idToken = getTokenResponseFromStorage()?.id_token;

    if (!idToken) {
        redirectToLogin();
        return;
    }

    window.location.href = `${keycloakConfig.url}/realms/${keycloakConfig.realm}/protocol/openid-connect/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(keycloakConfig.logoutRedirectUri)}`;
};

export const getUserFromStorage = (): User | null => {
    const user = localStorage.getItem('user');

    if (!user) {
        return null;
    }

    const parsedUser = JSON.parse(user) as User;
    const userColor = usercolors[parsedUser.id % usercolors.length];

    return {...parsedUser, color: userColor} as User;
};

export const saveUserToStorage = (user: User) => {
    localStorage.setItem('user', JSON.stringify(user));
};

export const removeUserFromStorage = () => {
    localStorage.removeItem('user');
};

export const removeTokenResponseFromStorage = () => {
    localStorage.removeItem('auth');
};
