import { Icon, IconButton, Tooltip, VStack } from '@chakra-ui/react';
import { ComponentType } from 'common/enums';
import React, { useEffect, useRef } from 'react';
import { PiTextAaBold } from 'react-icons/pi';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import AlignToolbar from 'src/templates/blueprint/toolbars/AlignToolbar';
import AddChartPopover from './AddChartPopover';
import AddComponentMenu from './AddComponentMenu';
import PagesPopover from './PagesPopover/PagesPopover';
import { useCanvasScroll } from './utils';

import { BiRedo, BiUndo } from 'react-icons/bi';
import { FaRegImage } from 'react-icons/fa6';
import { useHasScopePermissions } from 'src/auth/useHasScopePermissions';
import { getEnv } from 'src/utils';

import { useAppSelector } from '@hooks';
import { selectActiveReportPageId } from 'src/redux/features/blueprint/bluePrintSlice';
import { REPORT_PAGES, yDoc } from 'src/redux/yjs';
import * as Y from 'yjs';

enum OpenPopover {
    PAGES,
    CHARTS,
    MENU
}

const env = getEnv();

const Sidebar: React.FC = () => {
    const [isOpened, setIsOpened] = React.useState<OpenPopover | undefined>(undefined);
    const { actions, state } = useCanvas();
    const { scrollX, scrollY, canvasHeight } = useCanvasScroll();
    const x = state.width ? state.width / 2 : scrollX;
    const y = scrollY + canvasHeight / 2;

    const hasEditorPermission =
        useHasScopePermissions({
            any: ['editor']
        }) || env === 'test';

    const onToggle = (popover: OpenPopover) => {
        if (isOpened === popover) {
            setIsOpened(undefined);
        } else {
            setIsOpened(popover);
        }
    };

    const undoManagerRef = useRef<null | Y.UndoManager>(null);

    const activeReportPageId = useAppSelector(selectActiveReportPageId);

    useEffect(() => {
        const subscribe = () => {
            const yMapPages = yDoc.getMap(REPORT_PAGES);
            if (!undoManagerRef.current && yMapPages && activeReportPageId) {
                const undoManager = new Y.UndoManager(yMapPages, {
                    trackedOrigins: new Set([activeReportPageId])
                });
                undoManagerRef.current = undoManager;
            }
        };

        subscribe();

        return () => {
            if (undoManagerRef.current) {
                undoManagerRef.current.clear();
                undoManagerRef.current.destroy();
                undoManagerRef.current = null;
            }
        };
    }, [activeReportPageId, yDoc, REPORT_PAGES]);

    const handleUndo = () => {
        if (undoManagerRef.current) {
            undoManagerRef.current.undo();
        }
    };

    const handleRedo = () => {
        if (undoManagerRef.current) {
            undoManagerRef.current.redo();
        }
    };

    return (
        <VStack
            w="60px"
            height="calc(100vh - 48px)"
            bg="gray.800"
            color="black"
            p={4}
            spacing={4}
            style={{ backgroundColor: '#f7f7f7' }}
        >
            <PagesPopover
                isOpen={isOpened === OpenPopover.PAGES}
                setIsOpen={(value: boolean) =>
                    value ? setIsOpened(OpenPopover.PAGES) : setIsOpened(undefined)
                }
            />

            {hasEditorPermission && (
                <>
                    <AddChartPopover
                        isOpen={isOpened === OpenPopover.CHARTS}
                        setIsOpen={(value: boolean) =>
                            value
                                ? setIsOpened(OpenPopover.CHARTS)
                                : setIsOpened(undefined)
                        }
                    />
                    <AddComponentMenu
                        isOpen={isOpened === OpenPopover.MENU}
                        onToggle={() => onToggle(OpenPopover.MENU)}
                    />
                    <Tooltip
                        label="Add a text field"
                        aria-label="Add a text field"
                        placement="right"
                    >
                        <IconButton
                            id="editor-sidebar-text"
                            variant="icon"
                            aria-label="Add text"
                            onClick={() => actions.addComponent(ComponentType.TEXT, x, y)}
                            icon={<Icon h="60px" as={PiTextAaBold} />}
                        />
                    </Tooltip>
                    <Tooltip
                        label="Add an image"
                        aria-label="Add an image"
                        placement="right"
                    >
                        <IconButton
                            id="editor-sidebar-image"
                            variant="icon"
                            aria-label="Add image"
                            onClick={() =>
                                actions.addComponent(ComponentType.IMAGE, x, y)
                            }
                            icon={<Icon h="60px" as={FaRegImage} />}
                        />
                    </Tooltip>
                    {/* undo redo */}
                    <Tooltip label="Undo" aria-label="Undo" placement="right">
                        <IconButton
                            id="editor-sidebar-undo"
                            variant="icon"
                            aria-label="Undo"
                            onClick={() => handleUndo()}
                            icon={<Icon h="60px" as={BiUndo} />}
                        />
                    </Tooltip>
                    <Tooltip label="Redo" aria-label="Redo" placement="right">
                        <IconButton
                            id="editor-sidebar-redo"
                            variant="icon"
                            aria-label="Redo"
                            onClick={() => handleRedo()}
                            icon={<Icon h="60px" as={BiRedo} />}
                        />
                    </Tooltip>

                    <AlignToolbar layout="vertical" />
                </>
            )}
        </VStack>
    );
};

export default Sidebar;
