import { api } from '@api';
import { ComponentType } from 'common/enums';
import { Component, ReportPage } from 'common/types';
import { WebrtcProvider } from 'y-webrtc';
import * as Y from 'yjs';
import { Transaction, YEvent } from 'yjs';
import { bluePrintSlice } from './features/blueprint/bluePrintSlice';
import { AppDispatch, store } from './store';
import * as authService from 'src/auth/authService';

type ComponentWithPageId = Component & { pageId: number };

export const WebRTC_ROOM_NAME = 'report-pages-room';
export const REPORT_PAGES = 'report-pages';
const DEBUG = false;

const log = (...args: any[]) => {
    if (DEBUG) {
        console.log(...args);
    }
};

const SIGNALING_SERVER = import.meta.env.VITE_SIGNALING_SERVER;

// Initialize Yjs document
export const yDoc = new Y.Doc();
export const provider = new WebrtcProvider(WebRTC_ROOM_NAME, yDoc, {
    ...(SIGNALING_SERVER && { signaling: SIGNALING_SERVER })
});


// Get awareness instance
const awareness = provider.awareness;

type PageMap = Y.Map<any>;
type YMapPages = Y.Map<PageMap>;
const yMapPages: YMapPages = yDoc.getMap(REPORT_PAGES);

const changesAreEmpty = (changes: Record<string, any>) =>
    Object.keys(changes).length === 0;

const changesHasSingleComponent = (changes: Record<string, any>) =>
    Object.values(ComponentType).includes(changes?.type);

const changesHasMultipleComponents = (changes: Record<string, any>) =>
    Object.keys(changes).some((componentId: string) =>
        Object.values(ComponentType).includes(changes[componentId]?.type)
    );

const changesHasPages = (changes: Record<string, any>) =>
    Object.keys(changes)?.[0]?.includes('components');

const isChangeOnActivePage = (pageId: string | number) => {
    const storeActiveReportPageId = store.getState().blueprint.activeReportPageId;
    return pageId?.toString() === storeActiveReportPageId?.toString();
};

const syncSingleComponent = (changes: Record<string, any>) => {
    const { pageId, ...component } = changes;
    if (isChangeOnActivePage(pageId)) {
        log('Syncing single component', component);
        store.dispatch(
            bluePrintSlice.actions.setComponentConfig({
                id: component.id,
                component: component as Component
            })
        );
    }
};

const filterComponentsOnActivePage = (components: ComponentWithPageId[]): any[] => {
    const storeActiveReportPageId = store.getState().blueprint.activeReportPageId;
    return components.map((component) => {
        if (component.pageId === storeActiveReportPageId) {
            const { pageId, ...componentWithoutPageId } = component;
            return componentWithoutPageId;
        }
    });
};

const syncMultipleComponents = (changes: Record<string, any>) => {
    const componentsOnActivePage = filterComponentsOnActivePage(Object.values(changes));
    store.dispatch(
        bluePrintSlice.actions.setBulkComponentConfig(
            componentsOnActivePage as Component[]
        )
    );
};

const syncPages = (changes: Record<string, any>) => {
    const activePageId = store.getState().blueprint.activeReportPageId?.toString();
    if (activePageId && changes[activePageId]) {
        const pageChanges = changes[activePageId];
        const components = pageChanges.components;
        const componentsOnActivePage = filterComponentsOnActivePage(
            Object.values(components)
        );
        store.dispatch(
            bluePrintSlice.actions.setBulkComponentConfig(
                componentsOnActivePage as Component[]
            )
        );
    }
};

const syncDeleteComponent = (_changes: Record<string, any>, target: Y.Map<any>) => {
    const componentId = Array.from(target?._map.keys()).pop();
    const componentPageMap = target?.parent?.parent?.toJSON();
    const chagePageId = Object.keys(componentPageMap ?? {})[0];
    if (isChangeOnActivePage(chagePageId) && componentId) {
        store.dispatch(
            bluePrintSlice.actions.deleteComponent({
                id: componentId
            })
        );
    }
};

const handleUpdateReduxState = (
    changes: Record<string, any>,
    target: Y.Map<any>,
    {
        singleComponent,
        multipleComponents,
        hasPages,
        empty
    }: {
        singleComponent: boolean;
        multipleComponents: boolean;
        hasPages: boolean;
        empty: boolean;
    }
) => {
    if (empty) {
        syncDeleteComponent(changes, target);
    }
    if (singleComponent) {
        syncSingleComponent(changes);
    }
    if (multipleComponents) {
        syncMultipleComponents(changes);
    }
    if (hasPages) {
        syncPages(changes);
    }
};

const processRemoteChanges = (changes: Record<string, any>, target: Y.Map<any>) => {
    const empty = changesAreEmpty(changes);
    const singleComponent = changesHasSingleComponent(changes);
    const multipleComponents = changesHasMultipleComponents(changes);
    const hasPages = changesHasPages(changes);
    log('Processing remote changes as', {
        singleComponent,
        multipleComponents,
        hasPages,
        empty
    });

    handleUpdateReduxState(changes, target, {
        singleComponent,
        multipleComponents,
        hasPages,
        empty
    });
};

const updateReportPageSingleComponent = (changes: Record<string, any>) => {
    const storeActiveReportPageId = store.getState().blueprint.activeReportPageId;
    const currentReduxPageComponents = store
        .getState()
        .blueprint.activeReport?.pages?.find(
            (page) => page.id === storeActiveReportPageId
        )?.components;
    if (isChangeOnActivePage(changes.pageId) && currentReduxPageComponents) {
        store.dispatch(
            api.endpoints.updateReportPage.initiate({
                id: storeActiveReportPageId,
                components: {
                    ...currentReduxPageComponents,
                    [changes.id]: changes
                }
            })
        );
    }
};

const updateReportPageMultipleComponents = (changes: Record<string, any>) => {
    const componentsOnActivePage = filterComponentsOnActivePage(Object.values(changes));
    const storeActiveReportPageId = store.getState().blueprint.activeReportPageId;
    const currentReduxPageComponents = store
        .getState()
        .blueprint.activeReport?.pages?.find(
            (page) => page.id === storeActiveReportPageId
        )?.components;
    if (currentReduxPageComponents && componentsOnActivePage.length > 0) {
        const newComponents = componentsOnActivePage.reduce(
            (acc, component) => ({
                ...acc,
                [component.id]: component
            }),
            {}
        );
        store.dispatch(
            api.endpoints.updateReportPage.initiate({
                id: storeActiveReportPageId,
                components: {
                    ...currentReduxPageComponents,
                    ...newComponents
                }
            })
        );
    }
};

const handleUpdateReportPage = (
    changes: Record<string, any>,
    {
        singleComponent,
        multipleComponents
    }: {
        singleComponent: boolean;
        multipleComponents: boolean;
    }
) => {
    if (singleComponent) {
        updateReportPageSingleComponent(changes);
    }
    if (multipleComponents) {
        updateReportPageMultipleComponents(changes);
    }
};

const processLocalChanges = (changes: Record<string, any>) => {
    const singleComponent = changesHasSingleComponent(changes);
    const multipleComponents = changesHasMultipleComponents(changes);
    log('Processing local changes as', { singleComponent, multipleComponents });

    handleUpdateReportPage(changes, {
        singleComponent,
        multipleComponents
    });
};

const handleChange = (event: YEvent<any>, transaction: Transaction) => {
    const target = event.target as Y.Map<any>;
    log('Handling event', event, transaction);
    if (transaction.local) {
        log('Detected local change', target.toJSON(), transaction.origin);
    } else {
        log('Detected remote change', target.toJSON(), transaction.origin);
    }
    if (
        !transaction.local ||
        (transaction.origin && transaction.origin instanceof Y.UndoManager)
    ) {
        const changes = target.toJSON();
        processRemoteChanges(changes, target);
    }
    if (
        transaction.local &&
        transaction.origin &&
        transaction.origin instanceof Y.UndoManager
    ) {
        const changes = target.toJSON();
        processLocalChanges(changes);
    }
};

yMapPages.observeDeep((events: YEvent<any>[], _transaction: Transaction) => {
    events.forEach((event) => handleChange(event, event.transaction));
});

type AppAction = ReturnType<AppDispatch>;

const yjsMiddleware = () => (next: AppDispatch) => (action: AppAction) => {
    const result = next(action);

    switch (action.type) {
        case 'yjs/setBulkComponentConfig':
            updateBulkComponentConfig(action.payload as Component[]);
            break;
        case 'yjs/setComponentConfig':
            updateSingleComponentConfig(
                action.payload as { id: string; component: Component }
            );
            break;
        case 'yjs/deleteComponent':
            deleteComponent(action.payload as { id: string });
            break;
        case 'api/executeQuery/fulfilled':
            if ((action as any).meta.arg.endpointName === 'getReportBySlug') {
                const user = authService.getUserFromStorage();
                awareness.setLocalState({
                    user: {
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                        id: user?.id,
                        activeOpenedReports: [(action as any).payload.slug],
                        color: user?.color!
                    }
                });
                initPages((action as any).payload.pages as ReportPage[]);
            }
            break;
        default:
            break;
    }

    return result;
};

const initPages = (pages: ReportPage[]) => {
    pages.forEach((page) => {
        // Create a new page if it doesn't exist
        if (!yMapPages.has(page.id.toString())) {
            yDoc.transact(() => {
                const pageMap = getOrCreateYMap(yMapPages, page.id.toString());
                const componentsMap = getOrCreateYMap(pageMap, 'components');
                Object.values(page.components).forEach((component) => {
                    const componentWithPageId = { ...component, pageId: page.id };
                    const componentMap = getOrCreateYMap(componentsMap, component.id);
                    updateYMapWithValues(componentMap, componentWithPageId);
                });
            }, page.id);
        }
    });
};

const updateBulkComponentConfig = (updatedComponents: Component[]) => {
    const pageId = store.getState().blueprint.activeReportPageId;
    if (!pageId) {
        return;
    }

    yDoc.transact(() => {
        let pageMap = getOrCreateYMap(yMapPages, pageId.toString());
        let componentsMap = getOrCreateYMap(pageMap, 'components');

        updatedComponents.forEach((component) => {
            let componentMap = getOrCreateYMap(componentsMap, component.id);
            const componentWithPageId = { ...component, pageId };
            updateYMapWithValues(componentMap, componentWithPageId);
        });
    }, pageId);
};

const updateSingleComponentConfig = ({
    id,
    component
}: {
    id: string;
    component: Component;
}) => {
    const pageId = store.getState().blueprint.activeReportPageId;
    if (!pageId) {
        return;
    }

    yDoc.transact(() => {
        let pageMap = getOrCreateYMap(yMapPages, pageId.toString());
        let componentsMap = getOrCreateYMap(pageMap, 'components');
        let componentMap = getOrCreateYMap(componentsMap, id);
        const componentWithPageId = { ...component, pageId };

        updateYMapWithValues(componentMap, componentWithPageId);
    }, pageId);
};

const deleteComponent = ({ id }: { id: string }) => {
    const pageId = store.getState().blueprint.activeReportPageId;
    if (!pageId) {
        return;
    }

    yDoc.transact(() => {
        let pageMap = getOrCreateYMap(yMapPages, pageId.toString());
        let componentsMap = getOrCreateYMap(pageMap, 'components');
        componentsMap.delete(id);
    }, pageId);
};

const getOrCreateYMap = (parent: any, key: string) => {
    let map = parent.get(key);
    if (!map) {
        map = new Y.Map();
        parent.set(key, map);
    }
    return map;
};

const updateYMapWithValues = (map: Y.Map<any>, values: Record<string, any>) => {
    Object.entries(values).forEach(([key, value]) => {
        const existingValue = map.get(key);

        if (typeof value === 'object') {
            if (JSON.stringify(existingValue) !== JSON.stringify(value)) {
                map.set(key, value);
            }
        } else if (existingValue !== value) {
            map.set(key, value);
        }
    });
};

export default yjsMiddleware;
