import { Avatar, AvatarGroup, Tooltip } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { provider } from 'src/redux/yjs';
import * as authService from 'src/auth/authService';

interface User {
    firstName: string;
    lastName: string;
    id: number;
    activeOpenedReports: string[];
    color: string;
}

const TopBarActiveUsers = () => {
    const user = authService.getUserFromStorage();
    const [peers, setPeers] = useState<{ id: number; user: User | null }[]>([]);
    const awareness = useRef<typeof provider.awareness | null>(null);

    useEffect(() => {
        const getPeers = () => {
            const states = provider.awareness.getStates();
            const newPeers = Array.from(states.entries() || [])
                .map(([id, state]) => ({ id, user: state.user || null }))
                .filter((peer) => peer.user && peer.user?.id !== user?.id)
            .filter((peer, index, self) => self.findIndex((p) => p.user.id === peer.user.id) === index);
             // Only update state if newPeers is different from current peers
             if (JSON.stringify(newPeers) !== JSON.stringify(peers)) {
                setPeers(newPeers);
            }
        };

        if (!awareness.current) {
            awareness.current = provider.awareness;
            getPeers();
            awareness.current.on('change', getPeers);
        }

        return () => {
            if (awareness.current) {
                awareness.current.off('change', getPeers);
                awareness.current = null;
            }
        };
    }, [user?.id]); // Add provider.awareness to dependencies

    return (
        <AvatarGroup size="sm" max={6} gap={1}>
             {peers.length && <Avatar name={`${user?.firstName} ${user?.lastName}`} bg={user?.color} />}
            {peers.map((peer, index) => (
                <Avatar key={index} name={`${peer.user?.firstName} ${peer.user?.lastName}`} bg={peer.user?.color} />
            ))}
        </AvatarGroup>
    );
};

export default TopBarActiveUsers;
