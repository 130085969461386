import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { api } from './api';
import authReducer from './features/auth/authSlice';
import blueprintReducer from './features/blueprint/bluePrintSlice';
import yjsMiddleware from './yjs';



const rootReducer = combineReducers({
    auth: authReducer,
    blueprint: blueprintReducer,
    [api.reducerPath]: api.reducer
});


export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
    .concat(yjsMiddleware as unknown as  ReturnType<typeof getDefaultMiddleware>)
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;