import { useUpdatePageComponentsMutation } from '@api';
import { useToast } from '@chakra-ui/react';
import { Component } from 'common/types';
import { reportPageValidator } from 'common/validators';
import {
    deleteComponent,
    selectActiveReportPage,
    setBulkComponentConfig,
    setComponentConfig
} from 'src/redux/features/blueprint/bluePrintSlice';
import { assertIsDefined } from 'src/templates/blueprint/utils';
import { useAppDispatch, useAppSelector } from './redux';
import { useFetchReportPageData } from './useFetchReportPageData';
import { useCallback } from 'react';

export const useSetComponentConfig = () => {
    const dispatch = useAppDispatch();
    const activeReportPage = useAppSelector(selectActiveReportPage);
    const [updateReportPage] = useUpdatePageComponentsMutation();
    const fetchPageData = useFetchReportPageData();

    const toast = useToast();

    return useCallback(
        async (component: Component) => {
            const validation = reportPageValidator.component.safeParse(component);

            if (validation?.error) {
                toast({
                    title: 'There was an error with saving',
                    description: validation.error.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: 'top-right'
                });

                return;
            }

            assertIsDefined(activeReportPage);

            const currentComponent = activeReportPage.components[component.id];

            dispatch(setComponentConfig({ id: component.id, component: component }));

            let updatedSuccessfully = false;

            try {
                const response = await updateReportPage({
                    id: activeReportPage.id,
                    components: {
                        ...activeReportPage.components,
                        [component.id]: component
                    }
                }).unwrap();

                updatedSuccessfully = true;

                fetchPageData(
                    {
                        isSharedReport: false
                    },
                    response.components
                );
            } catch (e) {
                if (currentComponent) {
                    dispatch(
                        setComponentConfig({
                            id: component.id,
                            component: currentComponent
                        })
                    );
                } else {
                    dispatch(deleteComponent({ id: component.id }));
                }

                toast({
                    title: 'There was an error with saving',
                    description: 'An error occurred while saving',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: 'top-right'
                });
            } finally {
                if (updatedSuccessfully) {
                    dispatch({
                        type: 'yjs/setComponentConfig',
                        payload: {
                            id: component.id,
                            component: component
                        }
                    });
                } else {
                    dispatch({
                        type: 'yjs/setComponentConfig',
                        payload: {
                            id: component.id,
                            component: currentComponent
                        }
                    });
                }
            }
        },
        [activeReportPage, dispatch, toast, updateReportPage, fetchPageData]
    );
};

export const useSetBulkComponentConfig = () => {
    const dispatch = useAppDispatch();
    const activeReportPage = useAppSelector(selectActiveReportPage);
    const [updateReportPage] = useUpdatePageComponentsMutation();

    const toast = useToast();

    return async (components: Component[]) => {
        components.forEach((component) => {
            const validation = reportPageValidator.component.safeParse(component);

            if (validation?.error) {
                toast({
                    title: 'There was an error with saving',
                    description: validation.error.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: 'top-right'
                });

                return;
            }
        });

        assertIsDefined(activeReportPage);

        const currentComponents = activeReportPage.components;

        dispatch(setBulkComponentConfig(components));

        const componentsToUpdate = components.reduce(
            (acc, component) => ({
                ...acc,
                [component.id]: component
            }),
            {}
        );

        let updatedSuccessfully = false;

        try {
            await updateReportPage({
                id: activeReportPage.id,
                components: {
                    ...activeReportPage.components,
                    ...componentsToUpdate
                }
            }).unwrap();
            updatedSuccessfully = true;
        } catch (e) {
            Object.keys(components).forEach((componentId) => {
                if (currentComponents[componentId]) {
                    dispatch(
                        setComponentConfig({
                            id: componentId,
                            component: currentComponents[componentId]
                        })
                    );
                } else {
                    dispatch(deleteComponent({ id: componentId }));
                }
            });
            toast({
                title: 'There was an error with saving',
                description: 'An error occurred while saving',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            });
        } finally {
            if (updatedSuccessfully) {
                const currentComponentsAsArray = Object.values(componentsToUpdate);
                dispatch({
                    type: 'yjs/setBulkComponentConfig',
                    payload: currentComponentsAsArray // Update the YJS state
                });
            } else {
                const currentComponentsAsArray = Object.values(currentComponents);
                dispatch({
                    type: 'yjs/setBulkComponentConfig',
                    payload: currentComponentsAsArray // Revert to the previous state
                });
            }
        }
    };
};
